<template>
  <div class="single-bridge-common">
    <c-title title="历史运行状态" style="margin-bottom: 20px"></c-title>
    <div class="history-time" v-if="pageData && pageData.length > 0">
      <div class="history-time-title">统计时间段：{{pageData[pageData.length - 1].startTime}} - {{pageData[0].endTime}}</div>
      <div class="history-time-con">
        <div
            class="history-time-item"
            v-for="(item,i) in reversePageData"
            :key="i"
            :class="item.state === '在线' ? 'green' : 'red'"
            :style="'width:' + item.width * 100 + '%'"
        ></div>
      </div>
    </div>
    <div class="table-wrapper">
      <c-table
          :index="true"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
  import Configs from "./config/history";
  export default {
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        title:'',
        pageData:[],
        reversePageData:[],
        pageParams:{
          sensorNo:'',
          tableName:''
        }
      };

    },
    created() {
      this.pageParams.sensorNo = this.$route.query.sensorNo
      this.pageParams.tableName = this.$route.query.tableName
      this.pageGet()
    },
    methods: {
      pageGet(){
        this.$http.get("/equipment/situation/getHistoryOnline",{params:this.pageParams}).then(res => {
          if(res.success){
            if (res.data && res.data.length > 0){
              this.pageData = res.data
              let start = new Date(this.pageData[this.pageData.length - 1].startTime).getTime()
              let end = new Date(this.pageData[0].endTime).getTime()
              let sum = end - start
              this.pageData.forEach(item => {
                let s = new Date(item.startTime).getTime()
                let e = new Date(item.endTime).getTime()
                item.width = (e - s) / sum
              })
              this.reversePageData = JSON.parse(JSON.stringify(this.pageData)).reverse()
            }
          }
        })
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
  .opt-update:hover{
    cursor: pointer;
  }
  .history-time{
    width: 100%;
  }
  .history-time-con{
    width: 100%;
    display: flex;
    height: 30px;
    margin: 10px auto;
  }
  .history-time-item{
    height: 100%;
  }
  .green{
    background: #02c967;
  }
  .red{
    background: #dc0303;
  }
</style>