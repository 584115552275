export default function Configs(that) {
  const tableColumns = [
    {
      label: "设备编号",
      prop: "sensorNo",
      align: "center",
      show: true
    },
    {
      label: "状态",
      prop: "state",
      align: "center",
      show: true
    },
    {
      label: "开始时间",
      prop: "startTime",
      align: "center",
      show: true
    },
    {
      label: "结束时间",
      prop: "endTime",
      align: "center",
      show: true
    },
    {
      label: "持续时间",
      prop: "duration",
      align: "center",
      show: true
    },
  ];
  return {
    tableColumns
  };
}
